@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Orkney";
  src: local("Orkney"), url("./fonts/orkney-bold.otf") format("woff"),
    url("./fonts/Orkney\ Light.otf") format("woff"),
    url("./fonts/orkney-medium.otf") format("woff"),
    url("./fonts/orkney-regular.otf") format("woff");
}

body {
  font-family: "Inter", sans-serif;
}

h1,
h2,
h3,
h4 {
  font-family: "Orkney";
  font-weight: 700;
}

/* Add your Owl Carousel custom styles below */
.owl-carousel .owl-nav.disabled {
  display: block !important;
  margin: 0;
}

.small-slider .owl-next,
.small-slider .owl-prev {
  position: absolute;
  width: 40px;
  right: -20px;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex !important;
  justify-content: center;
  align-items: center;
  margin: 0 !important;
}

.small-slider .owl-prev {
  left: 0px;
}

.small-slider .owl-carousel span {
  color: #fff;
  font-size: 25px;
}
h1,
.banner-sec h2,
.banner-sec p {
  color: #fff;
}

h1 {
  font-size: 65px;
  line-height: 75px;
}
h1 span {
  font-weight: 300;
}
.banner-sec .container {
  position: relative;
  z-index: 2;
}
.mutilple-btns {
  margin-top: 35px;
  gap: 15px;
}

.content h2 {
  color: #0e1629;
  font-size: 35px;
  line-height: 45px;
}

.col img {
  height: 50px;
  object-fit: contain;
}
.services-banner .columns,
.cards-sec .columns {
  padding: 20px;
  border: 1px solid #0e1629;
  height: 100%;
}

.play-container {
  border: 1px solid #01bcac;
  width: 100%;
}
.small-slider .owl-theme .owl-nav {
  margin-top: 0;
}
.play-container button svg {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  fill: #01bcac;
}
.play-container input {
  height: 5px;
}
input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  height: 5px;
  background: #fff;
  border: 1px solid #01bcac;
  border-radius: 5px;
  outline: none;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: #01bcac;
  border-radius: 50%;
  cursor: pointer;
}

input[type="range"]::-moz-range-thumb,
input[type="range"]::-ms-thumb {
  width: 20px;
  height: 20px;
  background: #01bcac;
  border-radius: 50%;
  cursor: pointer;
}

.services-banner {
  background-color: #0e1629;
}

.services-banner::before {
  position: absolute;
  left: 0;
  width: 100%;
  bottom: 0;
  height: 380px;
  background-image: url("./images/Frame.png");
  content: "";
  filter: brightness(0) invert(1);
  background-repeat: no-repeat;
  opacity: 0.5;
  background-size: cover;
  z-index: 0;
}

.services-banner .colums img {
  margin: auto;
}
.services-banner .colums h6,
.services-banner .colums p {
  text-align: center;
}

.editor-banner h4::before,
.edited-banner h4::before {
  position: absolute;
  content: "";
  background-color: #01bcac;
  width: 8px;
  height: 100%;
  top: 0;
  left: 0;
}

.editor-banner::before {
  position: absolute;
  content: "";
  background-color: #dbf6f3;
  width: 100%;
  height: 500px;
  top: 0;
  left: 0;
}
.editor-banner .progress-bar-cover,
.edited-banner .progress-bar-cover,
.tabs-section .progress-bar-cover,
.filter-sec .progress-bar-cover {
  display: none;
}
.filter-sec nav a {
  border-radius: 100%;
  width: 50px;
  height: 50px;
  justify-content: center;
  border: 1px solid #01bcac;
  font-size: 22px;
}

.editor-banner .play-container,
.edited-banner .play-container,
.tabs-section .play-container,
.filter-sec .play-container {
  border: 0;
  padding: 0;
}

.bg-active {
  background: #01bcac;
  color: #fff;
  border-radius: 10px 10px 0px 0px;
}
.no-active {
  border: 1px solid #01bcac;
  border-radius: 10px 10px 0px 0px;
  padding: 15px 25px;
}
.big-tab-sec::after,
.filter-sec::after {
  position: absolute;
  top: 115px;
  left: 0;
  width: 100%;
  height: -webkit-fill-available;
  content: "";
  z-index: 1;
  background-color: #dbf6f3;
}
.filter-btn {
  border: 1px solid #c6c3c3;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 10px 15px;
}
.filter-sec::after {
  top: 65px;
}
.let-section .colums img {
  margin-right: auto;
}
.let-section .colums p {
  text-align: left;
}
.tab-btn img {
  filter: invert(48%) sepia(13%) saturate(3207%) hue-rotate(130deg)
    brightness(112%) contrast(80%);
}
.let-section .colums {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.card-block {
  border: 1px solid #01bcac;
}
.card-block button {
  padding: 10px;
}
.stars svg {
  fill: #ffab00;
}
.edited-banner .card-block {
  border: 1px solid #01bcac;
  padding: 20px;
}

.slick-section {
  background-color: #0e1629;
}
.edited-banner .card-block button {
  padding-left: 0px;
}

.resource-sec .columns {
  height: 100%;
  border: 1px solid #01bcac;
}

.slick-section span,
.crousel-section .owl-theme .owl-nav span,
.step-slider .owl-nav span {
  font-size: 0;
}
.step-form .step-slider .owl-prev {
  background-image: url("./images/leftarrow.png") !important;
}
.step-slider .owl-nav {
  display: flex;
  justify-content: space-between;
}
.slick-section .owl-prev,
.slick-section .owl-next,
.crousel-section .owl-next,
.crousel-section .owl-prev,
.step-slider .owl-prev,
.step-slider .owl-next {
  background-image: url("./images/Arrow-left.png") !important;
  background-repeat: no-repeat !important;
  background-size: 40px !important;
  width: 40px !important;
  height: 40px !important;
  cursor: pointer !important;
}
.slick-section button:hover,
.owl-nav button:hover,
.step-slider .owl-prev,
.step-slider .owl-next {
  background-color: unset !important;
}
.step-slider h4 {
    line-height: 45px;
    font-weight: 700;
    font-weight: 700;
    margin-bottom: 30px;
    color: #fff;
    font-size: 35px;
    text-align: center;
}   
.slick-section .owl-next,
.crousel-section .owl-next,
.step-slider .owl-next {
  background-image: url("./images/Arrow-right.png") !important;
}
.slick-section .owl-theme .owl-nav {
  display: flex;
  justify-content: center;
}

.crousel-section .item {
  border: 1px solid #0e1629;
  background-color: #fff;
  text-align: left;
  padding: 40px 20px;
}
.crousel-section .item img {
  width: 100%;
  max-width: 40px;
}
.crousel-section .item p {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.crousel-section .owl-prev,
.crousel-section .owl-next {
  position: absolute;
  left: 0px;
  top: 41%;
  transform: translate(-50%, -50%);
}

.crousel-section .owl-next {
  right: -30px;
  left: auto;
}
.crousel-section .owl-theme .owl-nav {
  margin-top: 30px;
}
.crousel-section .owl-dots {
  display: flex;
  align-items: center;
  justify-content: center;
}
.owl-theme .owl-dots .owl-dot span {
  width: 15px !important;
  height: 15px !important;
  background-color: transparent !important;
  border: 1.5px solid #01bcac;
}

.crousel-section .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background-color: #01bcac !important;
}

.blog-section .columns {
  border-radius: 15px;
  border: 1px solid #0e1629;
  background-color: #dbf6f3;
  padding: 30px 20px 60px;
  position: relative;
}

.blog-section .columns:hover {
  background-color: #01bcac;
}

.blog-section .box {
  border: 1px solid #0e1629;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: -36px;
  background-color: #fff;
}

.inside-col ul li {
  position: relative;
  padding-left: 23px;
  color: #0e1629;
  font-size: 16px;
  margin: 17px 0px;
  font-weight: 500;
}

.inside-col ul li::before {
  position: absolute;
  top: 2px;
  content: "";
  background-size: 15px !important;
  width: 15px;
  object-fit: cover;
  height: 25px;
  background: url("./images/angle-right.png") no-repeat;
  left: 0;
}
.option-sec::after {
  background: url("./images/Frame.png") no-repeat;
  position: absolute;
  bottom: 0;
  filter: brightness(0) invert(1);
  left: 0;
  width: 100%;
  opacity: 0.5;
  height: 400px;
  background-size: cover;
  content: "";
}
.languages li {
  margin: 20px 0px;
}

.social-footer {
  background-color: #ffffff1a;
}

.social-icon-footer a {
  background-color: #fff;
  padding: 5px;
  border-radius: 50%;
  display: flex;
  width: 35px;
  height: 35px;
  justify-content: center;
  align-items: center;
}
.social-icon-footer a svg {
  width: 20px;
  height: 20px;
  fill: #0e1629;
}
.mutilple-btns button:last-child {
  margin-bottom: 0;
  border: 1px solid #01bcac;
  padding: 10px 20px;
  color: #01bcac;
}

.multiple-btn a:last-child {
  border: 1px solid #01bcac;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-radius: 8px;
  color: #01bcac;
}
.border-outside {
  border-radius: 8px;
}

.filter-count #dropdownDefaultButton,
.border-outside {
  border: 1px solid #01bcac;
}
.preview-btn button {
  border: 1px solid #fff;
}
.styles-ul li::before {
  position: absolute;
  top: 4px;
  left: 0;
  content: "";
  width: 18px;
  height: 18px;
  background-size: 18px !important;
  background: url("./images/checked.png") no-repeat;
}
.styles-ul li {
  padding-left: 28px;
  position: relative;
  margin: 15px 0px;
  color: #5d5d5d;
}
.box-inside {
  background-color: #01bcac14;
  border-radius: 10px;
}
.inner-container {
  padding: 25px 35px;
}
.box-inside button {
  padding: 16px 25px;
}
.box-container {
  border: 1px solid #01bcac;
  max-width: 1000px;
  margin: 35px auto 0px;
}
.text-banner::before {
  height: 100% !important;
}

.back-layer {
  background: url("./images/back.png") no-repeat;
  background-size: cover;
}
.boxes-section .col,
.active-btns button {
  border: 1px solid #01bcac;
}
.boxes-section .col img {
  height: 100px;
}
.HowItWorks .active {
  background-color: #01bcac;
}

.actors-sec::after {
  position: absolute;
  background: url("./images/blue-bg.png") no-repeat;
  background-size: 100% 100%;
  content: "";
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
}
.border-style {
  border: 1px solid #c6c3c3;
  padding: 10px 20px;
}

.section-banner ol {
  padding: 0;
  margin-bottom: 40px;
}

.section-banner ol span {
  color: #fff;
  margin: 0px 10px;
}
.section-banner ol a {
  color: #fff;
  font-size: 17px;
  line-height: 23px;
}

.accordion-sec .block.relative {
  border: 1px solid #bebebe;
  background: #eeeeee;
  margin-bottom: 20px;
  border-radius: 8px;
}
.accordion-sec button {
  padding: 15px 20px;
}
.checkboxes-li li label span {
  color: #fff;
  margin: 0;
}
.overflow-hidden {
  overflow: hidden;
}

.custom-bg,
.single-profile .profile .custom-bg {
  background-color: #01bcac;
  border-radius: 15px;
}

.talents .active-btns {
  background: transparent;
}
.talents .active-btns button:last-child,
.HowItWorks .active-btns button:first-child {
  background-color: #01bcac;
  color: #fff;
}
.mutlip-tabs .boxes-inside {
  padding: 0;
}
.mutlip-tabs ul .absolute.inset-0.z-10.h-full.bg-white.rounded-md.shadow {
  background-color: #01bcac;
  box-shadow: unset;
  border-radius: 0;
  z-index: -1;
}

.mutlip-tabs nav {
  border: 1px solid #d4d2d2;
  border-radius: 10px;
  height: fit-content;
}
.mutlip-tabs ul li {
  padding: 13px 25px;
  color: #0e1629;
  font-weight: 500;
  justify-content: left;
}
.checkboxes-li li label {
  color: #fff;
  padding: 13px 20px;
  border: 1px solid #01bcac80;
  font-size: 18px;
  line-height: 23px;
  font-weight: 400;
  color: #fff;
  display: flex;
  border-radius: 15px;
}

.browse-content h2 {
  color: #fff;
}
.browse-content .checkboxes-li {
  justify-content: center;
  padding-bottom: 0;
}
.main-row li {
  display: flex;
  gap: 15px;
  color: #0e1629;
  font-size: 22px;
  line-height: 30px;
  font-weight: 600;
  align-items: center;
  margin-bottom: 25px;
}

.main-row li span {
  width: 100%;
  max-width: 70px;
  height: 70px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #01bcac;
  color: #0e1629;
  font-size: 30px;
  font-weight: 700;
  line-height: 40px;
}
.checkboxes-li li label input {
  display: none;
}
.innr-columns {
  border: 1px solid #01bcac;
  height: 100%;
}
.innr-columns h5 {
  margin: 10px 0px;
}
.innr-columns h6 {
  font-size: 30px;
  line-height: 40px;
  color: #01bcac;
  font-weight: 700;
}
.innr-columns svg {
  width: 35px;
  height: 35px;
  fill: #01bcac;
}
.innr-columns svg path {
  fill: #01bcac;
}
.innr-columns p {
  color: #01bcac;
  font-size: 21px;
  line-height: 28px;
  font-weight: 500;
}
.innr-columns hr {
  border-color: #01bcac;
  margin: 30px 0px;
}
.innr-columns ul {
  padding-left: 0 !important;
}
.innr-columns ul li {
  margin: 15px 0px;
}
.innr-columns ul li,
.innr-columns ul {
  color: #01bcac;
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
  padding-left: 35px;
  position: relative;
}
.innr-columns ul li::before {
  position: absolute;
  top: 3px;
  left: 0;
  width: 22px;
  height: 22px;
  background-size: 22px !important;
  background: url("./images/Star.svg") no-repeat;
  content: "";
}

.innr-columns:hover {
  background-color: #01bcac;
}
.innr-columns:hover *,
.innr-columns:hover .txt-icons svg path,
.innr-columns:hover .txt-icons svg,
.innr-columns:hover button:last-child,
.innr-columns:hover hr {
  color: white;
  background-color: transparent;
  fill: white;
  border-color: white;
}
.Membership .crousel-section {
  background-color: #0e1629;
}
.Membership .crousel-section .item {
  background-color: #0c333f;
  border: 1px solid #01bcac;
}

.Membership .crousel-section .item p,
.Membership .crousel-section .item h6 {
  color: #ffffff;
}
.innr-columns:hover button {
  background-color: #fff;
  color: #01bcac;
}
.inside-table td {
  padding: 10px 0px;
}
.innr-columns:hover ul li::before {
  filter: brightness(0) invert(1);
}
.Membership .crousel-section .item span {
  display: none;
}
.innr-columns:hover button img,
.Membership .crousel-section .item img,
.premium-btn img {
  filter: invert(48%) sepia(13%) saturate(3207%) hue-rotate(130deg)
    brightness(112%) contrast(80%);
}

.login-right-con h3 {
  padding-bottom: 25px;
}
.login-right-con form {
  margin-top: 20px;
}
.login-right-con form input {
  color: #0e1629;
}
.login-right-con :is(#password, #email) {
  border-bottom: 2px solid #cdcdcd8c !important;
  border: 0;
  padding: 0;
  border-radius: 0;
}
.login-right-con button {
  margin: 0;
}
#email:focus,
#password:focus {
  box-shadow: unset;
  border-bottom: 2px solid #01bcac !important;
}

.profile-section::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #0e1629;
  height: 400px;
  content: "";
  z-index: -1;
}

.views {
  background-color: #dbf6f3;
  border-radius: 5px;
  font-size: 19px;
  line-height: 23px;
  padding: 5px 10px;
  font-weight: 500;
}

.profile,
.samples {
  box-shadow: 0 4px 6px rgba(0, 210, 199, 0.31);
  border: 1px solid rgba(228, 228, 228, 1);
  padding: 30px 20px;
  background-color: #fff;
}
.single-profile .play-container {
  padding: 0;
  border: 0;
  border-radius: 0;
  padding: 20px 0px;
  border-bottom: 1px solid #e4e4e4;
}
.profile-identity img {
  border: 2px solid #01bcac;
  border-radius: 50%;
}
.songname p {
  color: #0e1629;
  font-size: 14px;
  line-height: 25px;
  font-weight: 500;
}
.profile .checkboxes-li li label {
  background-color: #dbf6f3;
  color: #000;
  border: 0;
  font-weight: 600;
}
.profile .checkboxes-li li :is(span) {
  color: #000;
}
.profile .checkboxes-li {
  padding: 20px 0px 30px;
}
.rating-block {
  width: 75%;
}
.single-profile .rating-block h6 {
  margin-bottom: 0;
  font-size: 18px;
}
.star img {
  max-width: 15px;
}
.reviews-block {
  margin-bottom: 20px;
}
.profile-des h6 {
  margin-bottom: 10px;
  font-size: 23px;
}
.songname span {
  color: #01bcac;
  font-size: 13px;
  line-height: 20px;
  font-weight: 500;
}

.step-slider form label,
.step-slider textarea,
.step-slider input {
  font-size: 16px;
}
.step-slider select,
.step-slider textarea,
.step-slider input {
  border: 1px solid #01bcac;
  background-color: #0c333f;
}
.tab-boxs {
  gap: 50px;
}
.tab-boxs ul {
  padding: 0;
}

@media (max-width: 1024px) {
  .play-container {
    width: 100%;
  }
  .single-profile .row {
    display: block;
  }
  h1 {
    font-size: 40px;
    line-height: 50px;
  }
  .cards-sec h2,
  .voice-sec h2,
  .back-layer h2,
  .services-banner h2,
  .editor-banner h2,
  .mutlip-tabs h2,
  .edited-banner h4,
  .actors-sec h2,
  .editor-banner h4,
  .let-section h2,
  .slick-section h2,
  .option-sec h2,
  .browse-content h2,
  .blog-section h2,
  .about-content h2,
  .resource-sec h2,
  .inside-col h2,
  .big-tab-sec h2,
  .innr-content h2,
  .Guide h2,
  .accordion-sec h2,
  .HowItWorks h2,
  .boxes-inside h2 {
    font-size: 35px;
    line-height: 45px;
  }
  footer .container {
    flex-wrap: wrap;
    align-items: unset;
  }

  footer .col-1 {
    width: 100%;
    padding: 0;
  }

  footer .col-2,
  footer .col-3,
  footer .col-4 {
    width: 50%;
    padding: 0;
  }
}

@media (min-width: 1024px) {
  .main-row li:last-child {
    margin-bottom: 0;
  }
}

@media (max-width: 992px) {
  .slick-section .row {
    grid-template-columns: 1fr;
  }
  .Membership table {
    overflow-x: auto;
    white-space: nowrap;
    display: block;
  }
  .Membership table td button {
    flex-wrap: wrap;
  }
  .blog-section .box {
    bottom: -16px;
  }
  .tab-boxs {
    display: flex;
    flex-direction: column-reverse;
    gap: 30px;
  }
  .tab-boxs ul {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .navbar {
    display: none;
  }
  .box-inside {
    gap: 10px;
  }
  .multiple-btn {
    display: none;
  }
  .left-box {
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }
  .text-banner .row,
  .text-banner p {
    gap: 20px;
    text-align: center;
  }
  .btn-speech {
    text-align: center;
  }
  .banner-sec .row {
    display: flex;
    flex-direction: column;
    gap: 35px;
  }
  .social-footer ul {
    gap: 10px;
    justify-content: center;
    flex-wrap: wrap;
  }
  .banner-sec .col-left,
  .banner-sec .col-right {
    width: 100%;
    text-align: center;
  }
  .mutilple-btns {
    justify-content: center;
  }
  .crousel-section .owl-prev,
  .crousel-section .owl-next {
    position: unset;
    transform: unset;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .navbar {
    width: 43.33%;
  }
  .logo-img {
    width: 23.33%;
  }
}

@media (max-width: 575px) {
  h1 {
    font-size: 35px;
    line-height: 45px;
  }
  .services-banner .colums {
    padding: 0;
  }
  .reviews-block {
    flex-direction: column-reverse;
    gap: 15px;
    align-items: baseline;
    margin-bottom: 30px;
  }
  .rating-block {
    width: 100%;
  }
  .image-text-blk .row,
  .text-img-sec .row {
    padding: 20px;
  }
  .services-banner .colums img {
    max-width: 100px;
  }
  .languages li {
    margin: 15px 0px;
  }
  footer .col-2,
  footer .col-3,
  footer .col-4 {
    width: 100%;
  }
  .blog-section .columns {
    max-width: 70%;
    margin: auto;
    width: 100%;
  }
  .content h2,
  .cards-sec h2,
  .voice-sec h2,
  .services-banner h2,
  .editor-banner h2,
  .Guide h2,
  .edited-banner h2,
  .slick-section h2,
  .edited-banner h4,
  .blog-section h2,
  .inside-col h2,
  .about-content h2,
  .browse-content h2,
  .column h2,
  .accordion-sec h2,
  .back-layer h2,
  .option-sec h2,
  .resource-sec h2,
  .big-tab-sec h2,
  .actors-sec h2,
  .HowItWorks h2,
  .let-section h2,
  .innr-content h2 .boxes-inside h2 {
    font-size: 30px;
    line-height: 40px;
  }
}

@media (max-width: 480px) {
  .mutilple-btns {
    flex-wrap: wrap;
  }
  .progress-bar-cover h6 {
    font-size: 18px;
  }
  .progress-bar-cover span {
    font-size: 15px;
  }
  .mutlip-tabs .boxes-inside > div:first-child {
    padding: 0;
  }
}
