.sectionBanner  {
    background: url('../images/background.png') no-repeat;
    background-size: cover;
} 

.sectionBanner::after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 450px;
    background: url('../images/bg-shades.png') no-repeat;
    content: "";
    background-size: 100% 100%;
    z-index: 1;
}