
.banner {
    background-color: #0E1629;
}

.bannersec::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../images/Group\ _background.png');
    content: "";
    opacity: 0.1;
    background-repeat: no-repeat;
    background-size: cover;
}