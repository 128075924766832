.cardbg {
    background-color: #00B5A5;
}
.cardbg::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../images/Objects.png');
    content: "";
    opacity: 1;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 1;
    filter: brightness(0) invert(1);
}